// Header.scss

.navbar {
    transition: background-color 0.3s ease;
    border-bottom: 1px solid $light-gray;

    &.sticky {
        background-color: #ffffff;
        border-bottom: 1px solid $light-gray;
    }

    .navbar-toggler {
        border: unset !important;
        outline: unset;
        box-shadow: unset;

        &:hover,
        &:focus {
            outline: unset;
            box-shadow: unset;
        }
    }

    .navbar-brand {
        font-weight: bold;
        font-family: 'Niconne', cursive;
        font-size: $font-logo;
        color: $black;
        position: relative;

        &::before {
            content: '';
            background-color: $primary;
            position: absolute;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            left: -6%;
            z-index: -1;
            bottom: 0;
        }
    }

    .nav-link {
        color: $black !important;
        font-weight: 500;
        font-size: $font-sm;
        padding: 0 !important;
        position: relative;
        transition: color 0.3s ease;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 2px;
            background-color: $primary;
            transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:hover {
            color: $primary !important;

            &::before {
                width: 100% !important;
            }
        }
    }

    .contact-btn {
        border-radius: $border-sm;
        color: $white;
        background-color: $primary;

        span {
            width: max-content;
            cursor: pointer;
            background: transparent;
            outline: none;
            transition: 1s ease-in-out;
        }

        svg {
            position: absolute;
            left: 0;
            width: 100%;
            border-radius: $border-sm;
            top: 0;
            fill: none;
            height: 100%;
            stroke: $white;
            stroke-dasharray: 150 480;
            stroke-dashoffset: 150;
            transition: 1s ease-in-out;
        }

        &:hover {
            transition: 1s ease-in-out;
            border-radius: $border-sm;
            background: $primary-dark;
        }

        &:hover svg {
            stroke-dashoffset: -480;
        }
    }
}


.navbar-collapse {
    &.show .navbar-nav {
        align-items: flex-end !important;

        .nav-item {
            margin-bottom: 20px;
        }
    }
}


.sticky-top {
    top: -1px !important;
}