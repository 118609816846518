.title-icon{
    .red-title-icon{
        color: $primary;
        font-size: $font-sm;
    }
    img{
        margin-right: 0.5rem;
    }
}

.cubes{
    position: absolute;
    left: 0;
    top: 0;
}

.gray-cubes{
    position: absolute;
    left: 0;
    bottom: 0;
}

.bg-main-black{
    background-color: $black;
}

.about-image-wrap{
    width: 70%;
}

.about-image{
    z-index: 11;
    position: relative;
}

.ellipse{
    left: 0;
    z-index: 0;
}

.about-icon{
    background-color: $primary;
    border-radius: $border-sm;
    padding: 16px;
}

.about-icon-globe{
    position: absolute;
    top: -12%;
    left: 60%;
}

.about-icon-pin{
    position: absolute;
    top: 40%;
    right: 10%;
}

.about-icon-feedback{
    position: absolute;
    bottom: -8%;
    left: 60%;
}

.about-title{
    font-size: $font-lg;
    width: max-content;
    font-weight: 700;
    text-transform: capitalize;
}