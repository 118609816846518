.work-title {
    font-size: $font-lg;
    width: max-content;
    font-weight: 700;
    margin: 0 auto;
    text-transform: capitalize;

    &.rec-red {
        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.image-swiper-button-next {
    position: absolute;
    z-index: 22;
    cursor: pointer;
    right: -2%;
    top: 60%;
    transform: translateY(50%);
}

.image-swiper-button-prev {
    position: absolute;
    z-index: 22;
    cursor: pointer;
    left: -2%;
    top: 60%;
    transform: translateY(50%);
}

.opacity-50 {
    opacity: 0.5;
}

.swipe-item {
    background-color: $light-gray;
    padding: 10px;
    border-radius: $border-sm;
    text-align: center;

    img {
        width: 100%;
        border-radius: $border-sm;
    }

    h3 {
        color: $black;
        text-align: left;
        font-weight: 500;
        font-size: $font-sm;
    }

    p {
        color: $black;
        text-align: left;
    }

    .date {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            width: 16px;
        }

        span{
            color: $black;
            text-align: left;
            font-weight: 500;
            font-size: $font-xs;
        }
    }
}