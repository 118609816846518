.contact-title {
    font-size: $font-lg;
    width: max-content;
    font-weight: 700;
    text-transform: capitalize;
}

.form-wrap {
    background-color: $black;
    padding: 30px;
    position: relative;

    &::before {
        border-left: 4px solid $black;
        border-top: 4px solid $black;
        width: 40%;
        height: 40%;
        left: -2%;
        position: absolute;
        top: -2%;
        content: '';
        z-index: -1;
    }

    &::after {
        background-color: $primary;
        width: 80%;
        height: 70%;
        right: -2%;
        position: absolute;
        bottom: -2%;
        content: '';
        z-index: -1;
    }

    .form-item {
        color: $white;
        margin-bottom: 20px;
    }

    .form-control {
        background-color: unset;
        color: $white;
        border: unset;
        padding: 6px 0;

        &:focus {
            background-color: unset;
        }
    }

    input,
    textarea {
        outline: none;
        box-shadow: unset;
        margin-top: 14px;
        border-radius: 0;
        border-bottom: 1px solid $white !important;

        &:focus,
        &:hover {
            outline: none;
            box-shadow: unset;
        }
    }
}

.send-btn {
    background-color: $primary !important;
    color: $white;
    margin-top: 20px;
    padding: 8px 16px;
    border-radius: 0;
    font-size: $font-xs;
}

.error-message {
    color: red;
    font-size: small;
    margin-bottom: 0;
    margin-top: 4px;
}