.title {
    display: block;
    height: 100%;
    width: 100%;

    .red-title {
        font-size: $font-md;
        color: $primary;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 0;
    }

    .main-title {
        font-size: $font-xl;
        color: $black;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 0.5rem;
    }

    .occupation {
        font-size: $font-sm;
        color: $gray;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.7rem;
    }
}

.description {
    font-size: $font-xs;
    color: $gray;
    line-height: 2;
    font-weight: 300;
    letter-spacing: 0.5px;
}

.home-image {
    position: relative;

    &::before {
        border-left: 4px solid $black;
        border-top: 4px solid $black;
        width: 40%;
        height: 40%;
        left: -2%;
        position: absolute;
        top: -2%;
        content: '';
        z-index: -1;
    }

    &::after {
        background-color: $primary;
        width: 80%;
        height: 70%;
        right: -2%;
        position: absolute;
        bottom: -2%;
        content: '';
        z-index: -1;
    }

    @media (max-width: 575px) {
        width: 90%;
        margin: 0 auto;
    }
}

.follow-me-title {
    font-size: $font-sm;
    color: $gray;
    width: max-content;
    font-weight: 700;
    text-transform: capitalize;
}

.rec-red {
    &::after {
        background-color: $primary;
        width: 30%;
        height: 10px;
        left: 0;
        position: absolute;
        bottom: 0;
        content: '';
    }
}