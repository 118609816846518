$primary: #A91F22;
$primary-dark: #8A1113;
$gray: #A5A5A5;
$light-gray: #F5F5F5;
$white: #FFFFFF;
$black: #001715;

$border-sm: 4px;

$font-logo: 34px;
$font-xs: 16px;
$font-sm: 20px;
$font-md: 28px;
$font-lg: 34px;
$font-xl: 56px;